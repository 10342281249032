.hero {
  display: flex;
  flex-direction: column;

  padding: 0 2rem;
  gap: 20px;
}

.introduction {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;

  gap: 30px;
}

.introduction p {
  font-size: 0.8125rem;
  line-height: 1.63;
  letter-spacing: 0.2px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(22, 28, 45);
  margin: 0;
}

.introduction h2 {
  font-weight: 700;
  margin: 0;
  color: rgb(22, 28, 45);
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
}

.introduction button {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.63px;
  text-transform: uppercase;
  padding: 23px 30px;
  box-shadow: rgba(3, 3, 3, 0.12) 0px 32px 54px;
  border-radius: 50rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  user-select: none;
  transform: perspective(1px) translateZ(0px);
  position: relative;
  overflow: hidden;
  border: none;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  background-color: rgb(73, 95, 239);
  transition: all 0.4s ease-out 0s;
  outline: none !important;
  width: max-content;
  cursor: pointer;
}

.introduction button svg {
  margin-left: 1.5rem;
}

.introduction button:hover {
  transform: translateY(-10px);
  box-shadow: rgba(3, 3, 3, 0.14) 0px 32px 54px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile img {
  width: 100%;
}

@media (min-width: 768px) {
  .hero {
    max-width: 1440px;
    margin: auto;
    padding: 1rem 2rem;
    min-height: calc(100vh - 10rem);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .introduction h2 {
    font-size: 80px;
    line-height: 84px;
    margin: 0;
  }

  .profile {
    min-width: 400px;
  }
}

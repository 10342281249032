:root {
  --primary: #ead097;
  --secondary: #f2f3f3;
  --avatar-size-mobile: "";
  --avatar-size-tablet: "";
  --avatar-size-desktop: "";
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(22, 28, 45) !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  color: rgb(73, 95, 239);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smooth-transition {
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

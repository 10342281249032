nav {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.logo {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.42;
  display: flex;
}

.logo h4 {
  margin: 0;
}

.logo a:hover {
  color: inherit !important;
}

.cv-cta {
  font-size: 15px;
  font-weight: 700;
  text-transform: lowercase;
}

.social-links {
  display: flex;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  padding: 1rem 0;
}

.social-links li {
  list-style-type: none;
  padding: 0px 0.4rem;
}

@media (min-width: 768px) {
  nav {
    flex-direction: row;
  }

  .social-links {
    padding: 0;
    margin: 0;
    border: none;
  }

  .social-links li {
    padding: 0px;
  }

  .social-links li:not(:last-child)::after {
    content: "\B7";
    margin: 0 5px;
    font-size: 20px;
    color: #494949;
    vertical-align: middle;
  }
}

.hero {
  display: flex;
  flex-direction: column;

  padding: 0 2rem;
  grid-gap: 20px;
  gap: 20px;
}

.introduction {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;

  grid-gap: 30px;

  gap: 30px;
}

.introduction p {
  font-size: 0.8125rem;
  line-height: 1.63;
  letter-spacing: 0.2px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(22, 28, 45);
  margin: 0;
}

.introduction h2 {
  font-weight: 700;
  margin: 0;
  color: rgb(22, 28, 45);
  letter-spacing: -2.81px;
  font-size: 50px;
  line-height: 56px;
}

.introduction button {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1.63px;
  text-transform: uppercase;
  padding: 23px 30px;
  box-shadow: rgba(3, 3, 3, 0.12) 0px 32px 54px;
  border-radius: 50rem;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
          user-select: none;
  -webkit-transform: perspective(1px) translateZ(0px);
          transform: perspective(1px) translateZ(0px);
  position: relative;
  overflow: hidden;
  border: none;
  white-space: nowrap;
  color: rgb(255, 255, 255);
  background-color: rgb(73, 95, 239);
  transition: all 0.4s ease-out 0s;
  outline: none !important;
  width: -webkit-max-content;
  width: max-content;
  cursor: pointer;
}

.introduction button svg {
  margin-left: 1.5rem;
}

.introduction button:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: rgba(3, 3, 3, 0.14) 0px 32px 54px;
}

.profile {
  display: flex;
  align-items: center;
}

.profile img {
  width: 100%;
}

@media (min-width: 768px) {
  .hero {
    max-width: 1440px;
    margin: auto;
    padding: 1rem 2rem;
    min-height: calc(100vh - 10rem);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .introduction h2 {
    font-size: 80px;
    line-height: 84px;
    margin: 0;
  }

  .profile {
    min-width: 400px;
  }
}

footer {
  font-size: 1em;
  padding: 1rem 0.5rem;
  text-align: center;
}

:root {
  --primary: #ead097;
  --secondary: #f2f3f3;
  --avatar-size-mobile: "";
  --avatar-size-tablet: "";
  --avatar-size-desktop: "";
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255) !important;
  color: rgb(22, 28, 45) !important;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:active,
a:focus {
  text-decoration: none !important;
  outline: none !important;
  color: rgb(73, 95, 239);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smooth-transition {
  transition: 0.5s;
}


nav {
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.logo {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.42;
  display: flex;
}

.logo h4 {
  margin: 0;
}

.logo a:hover {
  color: inherit !important;
}

.cv-cta {
  font-size: 15px;
  font-weight: 700;
  text-transform: lowercase;
}

.social-links {
  display: flex;
  border-top: 1px solid gainsboro;
  border-bottom: 1px solid gainsboro;
  padding: 1rem 0;
}

.social-links li {
  list-style-type: none;
  padding: 0px 0.4rem;
}

@media (min-width: 768px) {
  nav {
    flex-direction: row;
  }

  .social-links {
    padding: 0;
    margin: 0;
    border: none;
  }

  .social-links li {
    padding: 0px;
  }

  .social-links li:not(:last-child)::after {
    content: "·";
    margin: 0 5px;
    font-size: 20px;
    color: #494949;
    vertical-align: middle;
  }
}
